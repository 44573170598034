import React from 'react'
import {
  Form,
  Table,
  Button,
  Container,
  Image,
  Spinner,
  Badge,
  Dropdown,
  Row,
  Col,
  ToastContainer,
  Toast,
} from 'react-bootstrap'
import { ToastCurrentTrip, ToastSendedBrouillon } from '../../component/ui/Toast/Toastes'
import { DetailOrderModal, SearchPlanningModal } from '../../component/ui/Modal/Modals'
import userStore from '../../stores/userStore'
import { useNavigate } from 'react-router-dom'
import {
  _getOrdersData2,
  _tagStatus,
  _transformDataToNested,
} from '../../utils/functions'
import { Tag } from 'rsuite'
import { OrderType } from '../../definitions/OrderType'
import OrdersService from '../../services/orders/OrdersService'
import PaginationComponent from '../../component/ui/PaginationComponent'
import noResult from '../../styles/images/no_result.png'
import { _switchStatus } from '../../utils/api/totaraApi'
import OrderTableTitle from '../../component/orders/OrderTableTitle'

export default function Order() {
  ///////////
  //store Data
  /////////////
  const dataStore = userStore((state: any) => state)
  const navigate = useNavigate()

  //////////////
  //State
  /////////////
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isFiltering, setIsFiltering] = React.useState<boolean>(false)
  const [errorOrderMessage, setErrorOrderMessage] = React.useState<{
    error: boolean
    message: string
  }>({
    error: false,
    message: '',
  })
  const [infoOrder, setInfoOrder] = React.useState<string>("")

  const [dataOrder, setDataOrder] = React.useState<any>()
  const [selectedOrder, setSelectedOrder] = React.useState<any>({})
  const [filteringData, setFilteringData] = React.useState<any>({
    date_creation: '',
    referenceHorsRevatua: '',
    destinataire_denomination: '',
    numeroVoyage: '',
    statut_revatua: '',
    navire: '',
    ileArrivee: '',
  })

  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(1)

  const [ordersForConnaissement, setOrdersForConnaissement] = React.useState<any>([])
// Toastes
  const [showA, setShowA] = React.useState<boolean>(false)
  const toggleShowA = () => setShowA(!showA)

  const [showBrouillon, setShowBrouillon] = React.useState<boolean>(false)
  const toggleShowBrouillon = () => setShowBrouillon(!showBrouillon)

  const [showErrorOrder, setShowErrorOrder] = React.useState<boolean>(false)
  const toggleShowErrorOrder = () => setShowErrorOrder(!showErrorOrder)

  const [showInfo, setShowInfo] = React.useState<boolean>(false)
  const toggleShowInfo = () => setShowInfo(!showInfo)

//Modals
  const [show, setShow] = React.useState(false)

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const [showSearchPlanning, setShowSearchPlanning] = React.useState(false)

  const handleCloseSearchPlanning = () => setShowSearchPlanning(false)
  const handleShowSearchPlanning = () => {
    if (ordersForConnaissement.length > 0) {
      setShowSearchPlanning(true)
    } else {
      // alert('Veuillez selectionner au moins une commande')
      toggleShowInfo()
      setInfoOrder("Veuillez sélectionner au moins une commande")
    }
  }

  React.useEffect(() => {
    if (!dataStore?.token || dataStore?.token === undefined) {
      navigate('/connexion')
    }
    if (dataStore.token) {
      _getOrdersData2(
        dataStore?.token,
        currentPage,
        setDataOrder,
        setTotalPages,
        setIsLoading,
        setErrorOrderMessage
      )
    }
  }, [])

  React.useEffect(() => {
    if (isFiltering) {
      filteredOrder(dataStore?.token, filteringData)
    } else {
      _getOrdersData2(
        dataStore?.token,
        currentPage,
        setDataOrder,
        setTotalPages,
        setIsLoading,
        setErrorOrderMessage
      )
    }
  }, [currentPage])


  //Gère les commandes selectionnées
  const handleSelectOrders = (order: OrderType) => {
    if (ordersForConnaissement.includes(order)) {
      setOrdersForConnaissement(ordersForConnaissement?.filter((item: any) => item !== order))
    } else {
      setOrdersForConnaissement([...ordersForConnaissement, order])
    }
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const validOrders = dataOrder?.filter((order: any) => {
        const isDifferentDestinataire =
          (ordersForConnaissement?.length > 0 &&
            ordersForConnaissement[0]?.destinataire?.denomination !==
              order?.destinataire?.denomination) ||
          (order?.statusRevatua !== 'A_PLANIFIER' && order?.statusRevatua !== 'BROUILLON')

        return !isDifferentDestinataire
      })

      if (validOrders?.length !== dataOrder?.length) {
        setInfoOrder("Vous devez sélectionner des commandes avec le même client et les status 'A_PLANIFIER' ou 'BROUILLON'")
        toggleShowInfo()
        setOrdersForConnaissement([])
      } else {
        setOrdersForConnaissement(validOrders)
      }
    } else {
      // Désélectionne toutes les commandes
      setOrdersForConnaissement([])
    }
  }
 

  const handlefilteredOrder = (e: any) => {
    const { name, value } = e.currentTarget
    const updatedFilteringData = {
      ...filteringData,
      [name]: value,
    }
    setFilteringData(updatedFilteringData)
  }

  const filteredOrder = async (token: any, filteringData: any) => {
    // Filtrer les paramètres qui ne sont pas définis ou sont vides
    const filteredParams = Object.entries(filteringData)
      .filter(([key, value]) => value) // Garde les paires où la valeur est définie (non null, non undefined, non vide)
      .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`) // Encode chaque paramètre
      .join('&') // Les concatène avec '&'

    try {
      const response = await OrdersService.filteredOrder(token, filteredParams, currentPage)
      setTotalPages(response.data.last_page) // Nombre total de pages
      setDataOrder(_transformDataToNested(response.data.data))
      setIsFiltering(true)
    } catch (error) {
      console.log(error)
      setIsFiltering(true)
    }
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }
  const status = [
    'A_PLANIFIER',
    'A_DEPLANIFIER',
    'BROUILLON',
    'DEMANDE',
    'DEMANDE_REFUSEE',
    'SAISIE',
    'OFFICIALISE',
    'OFFICIALISE_SOUS_RESERVE',
    'MODIFIE',
    'PRIS_EN_CHARGE',
    'EMBARQUE',
    'EMBARQUEMENT_REFUSE',
    'TRANSFERE',
    'ANNULE']
  
 

  const searchPlanningProps = {
    ordersForConnaissement,
    setOrdersForConnaissement,
    handleCloseSearchPlanning,
    toggleShowA,
    toggleShowBrouillon,
    dataOrder,
    setDataOrder,
  }
  const toastCurrentTripProps = { showA, toggleShowA }
  const toastSendedBrouillonProps = { showBrouillon, toggleShowBrouillon }
  const SearchPlanningModalProps = {
    showSearchPlanning,
    handleCloseSearchPlanning,
    searchPlanningProps,
  }
  const detailOrderModalProps = {
    show,
    selectedOrder,
    handleClose,
    setSelectedOrder,
    dataOrder,
    setDataOrder,
    setErrorOrderMessage,
    setIsLoading,
    currentPage,
    setTotalPages,
    setInfoOrder,
    toggleShowInfo,
  }

  //Si fitteringData est vide
  const isEmpty =
    filteringData.date_creation === '' &&
    filteringData.referenceHorsRevatua === '' &&
    filteringData.destinataire_denomination === '' &&
    filteringData.numeroVoyage === '' &&
    filteringData.statut_revatua === '' &&
    filteringData.ileArrivee === ''


  return (
    <div className='p-3 pb-5 mb-5'>
      <h3 className='text-secondary'>Factures</h3>

      <Table striped hover responsive className=' border'>
        <OrderTableTitle />
        <thead className=''>
          <tr>
            <th className='p-1 p-sm-2'>
              <Form.Check
                type='checkbox'
                id={`allCheck`}
                onChange={handleSelectAll}
                checked={
                  dataOrder?.length > 0 && ordersForConnaissement?.length === dataOrder?.length
                }
                required
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Date'
                name='date_creation'
                value={filteringData.date_creation || ''}
                onChange={handlefilteredOrder}
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Numéro Facture'
                name='referenceHorsRevatua'
                value={filteringData.referenceHorsRevatua}
                onChange={handlefilteredOrder}
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Client'
                name='destinataire_denomination'
                value={filteringData.destinataire_denomination || ''}
                onChange={handlefilteredOrder}
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='N°voyage'
                name='numeroVoyage'
                value={filteringData.numeroVoyage || ''}
                onChange={handlefilteredOrder}
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Select
                name='evenementConnaissement'
                value={filteringData.statut_revatua || ''}
                onChange={(e) => {
                  const updatedFilteringData = {
                    ...filteringData,
                    statut_revatua: e.currentTarget.value,
                  }
                  setFilteringData(updatedFilteringData)
                }}
                aria-label='zone'
                required
              >
                <option value='' className='text-ui-second'>
                  Statut
                </option>
                {status?.map((etat: any, index: any) => (
                  <option key={index} value={etat}>
                    {etat}
                  </option>
                ))}
              </Form.Select>
            </th>
            {/* <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Navire'
                name='navire'
                value={filteringData.navire || ''}
                onChange={handlefilteredOrder}
              />
            </th> */}
            <th className='responsive-font-small p-1 p-sm-2'>
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Arrivée'
                name='ileArrivee'
                value={filteringData.ileArrivee || ''}
                onChange={handlefilteredOrder}
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {/*
              EN ATTENTE
              <Button
                className='d-flex font-85 bg-remora-primary border-remora-primary'
                onClick={() => filteredOrder(dataStore.token, filteringData)}
                disabled={isEmpty}
              >
                <i className='ri-search-line me-0  me-md-2'></i>
                <span className='d-none d-md-block'>Rechercher</span>
              </Button>
              {isFiltering && (
                <span
                  className='pointer'
                  onClick={() => {
                    setFilteringData({
                      date_creation: '',
                      referenceHorsRevatua: '',
                      destinataire_denomination: '',
                      numeroVoyage: '',
                      statut_revatua: '',
                      ileArrivee: '',
                    })
                    _getOrdersData2(
                      dataStore.token,
                      currentPage,
                      setDataOrder,
                      setTotalPages,
                      setIsLoading,
                      setErrorOrderMessage
                    )
                    setIsFiltering(false)
                  }}
                >
                  <i className='ri-close-line fs-5'></i>
                  <u>Réinitialiser</u>
                </span>
              )} */}
            </th>
          </tr>
        </thead>
        <tbody>
          {(!errorOrderMessage.error || !isLoading) &&
            dataOrder?.length > 0 &&
            dataOrder?.map((order: OrderType, indx: number) => {
              const isDifferentDestinataire =
                (ordersForConnaissement?.length > 0 &&
                  ordersForConnaissement[0]?.destinataire?.denomination !==
                    order?.destinataire?.denomination)
                  const isNotBrouillon = order?.statusRevatua !== 'A_PLANIFIER' &&
                  order?.statusRevatua !== 'BROUILLON'
              return (
                <tr key={indx}>
                  <td
                    className='p-1 p-sm-2'
                    onClick={() => {
                      if (isDifferentDestinataire) {
                        setInfoOrder("Vous ne pouvez pas sélectionner des factures avec des destinataires différents")
                        toggleShowInfo()
                      }else if(isNotBrouillon){
                        setInfoOrder("Ces factures ne sont pas sélectionnables")
                        toggleShowInfo()
                      }
                    }}
                  >
                    {' '}
                    {order?.date_creation !== undefined && (
                      <Form.Check
                        type='checkbox'
                        id={`${order.id}`}
                        onChange={() => {
                          handleSelectOrders(order)
                        }}
                        checked={
                          ordersForConnaissement && ordersForConnaissement?.includes(order)
                        }
                        value={order?.referenceHorsRevatua}
                        disabled={isDifferentDestinataire || isNotBrouillon}
                        required
                      />
                    )}
                  </td>

                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2 '
                  >
                    {order?.date_creation !== undefined && order?.date_creation}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2 '
                  >
                    <span className='truncate'>{order?.referenceHorsRevatua} </span>
                  </td>

                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  >
                    {order?.destinataire?.denomination}{' '}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  >
                    {order?.numeroVoyage}
                  </td>
                  <td
                    onClick={() => {
                      if (
                        order?.statusRevatua !== 'A_PLANIFIER' &&
                        order?.statusRevatua !== 'A_DEPLANIFIER'
                      ) {
                        setSelectedOrder(order)
                        handleShow()
                      }
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  >
                    <Row className=''>
                      <Col xs={8}>
                        {' '}
                        <Tag
                          size='sm'
                          className={
                            order?.statusRevatua === 'A_DEPLANIFIER'
                              ? 'bg-dark text-light responsive-font-small '
                              : 'responsive-font-small'
                          }
                          color={_tagStatus(order?.statusRevatua)}
                        >
                          {order?.statusRevatua}
                        </Tag>
                      </Col>
                      {/* <Col xs={2} className='text-start ps-3'>
                        {(order?.statusRevatua === 'A_PLANIFIER' ||
                          order?.statusRevatua === 'A_DEPLANIFIER') && (
                          <Dropdown>
                            <Dropdown.Toggle
                              className='border-0'
                              variant='transparent'
                              id='dropdown-basic'
                            ></Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                className='responsive-font-small'
                                onClick={() => {
                                  if (order?.statusRevatua === 'A_PLANIFIER') {
                                    _switchStatus(dataStore.token, 'A_DEPLANIFIER', order.id, setErrorOrderMessage, dataOrder, setDataOrder, setShowErrorOrder)
                                  } else {
                                    _switchStatus(dataStore.token, 'A_PLANIFIER', order.id, setErrorOrderMessage, dataOrder, setDataOrder, setShowErrorOrder)
                                  }
                                }}
                              >
                                {order?.statusRevatua === 'A_PLANIFIER'
                                  ? 'A_DEPLANIFIER'
                                  : 'A_PLANIFIER'}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </Col> */}
                    </Row>
                  </td>
                  {/*
                  EN ATTENTE
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  >
                    {order?.navire}
                  </td> */}
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  >
                    {order?.ileArrivee}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  ></td>
                </tr>
              )
            })}

          {!isLoading && dataOrder?.length === 0 && (
            <tr className=''>
              <td></td>
              <td colSpan={7} className='text-center'>
                <Image src={noResult} height={32} /> Votre recherche n'a donné aucun résultat
              </td>
              <td></td>
            </tr>
          )}
        </tbody>
      </Table>
      {/*
      EN ATTENTE
      <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      /> */}
      {isLoading && (
        <Container
          fluid
          className='bg-body-tertiary d-flex justify-content-center align-items-center vh-100 text-center'
        >
          <Spinner variant='primary' /> <span className='ms-3'>Loading...</span>
        </Container>
      )}
      {errorOrderMessage?.error && (
        <ToastContainer
          containerPosition='fixed'
          position='top-end'
          className='mt-2 me-2'
          style={{ zIndex: 1500000 }}
        >
          <Toast
            show={showErrorOrder}
            onClose={toggleShowErrorOrder}
            bg='danger'
            className='text-light p-3'
            delay={10000}
            autohide
          >
       
            <i className='ri-error-warning-line fs-2 text-light  me-3'></i>
            {errorOrderMessage?.message}
            {/* </Alert> */}
          </Toast>
        </ToastContainer>
      )}
        <ToastContainer
          containerPosition='fixed'
          position='top-center'
          className='mt-2 me-2'
          style={{ zIndex: 1500000 }}
        >
          <Toast
            show={showInfo}
            onClose={toggleShowInfo}
            
            className='d-flex align-items-center bg-remora-primary text-light py-3 px-2'
            delay={5000}
            autohide
          >
            <i className='ri-error-warning-line fs-2 text-light  me-2'></i>
            {infoOrder}
         
          </Toast>
        </ToastContainer>

      {/*
      EN ATTENTE
      {ordersForConnaissement?.length > 0 && (
        <Badge
          bg='dark'
          className='m-auto p fab2 rounded-circle primary-colo'
          onClick={handleShowSearchPlanning}
        >
          {ordersForConnaissement?.length}
        </Badge>
      )} */}
      <Button
        // variant='primary'
        className='m-auto fab rounded-pill'
        onClick={handleShowSearchPlanning}
      >
        <i className='ri-sailboat-fill'></i> Planning
      </Button>

      <SearchPlanningModal SearchPlanningModalProps={SearchPlanningModalProps} />
      <DetailOrderModal detailOrderModalProps={detailOrderModalProps} />

      <ToastSendedBrouillon toastSendedBrouillonProps={toastSendedBrouillonProps} />
      <ToastCurrentTrip {...toastCurrentTripProps} />
    </div>
  )
}
