import React from 'react'
import {
  Alert,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Table,
} from 'react-bootstrap'
import SearchTest from '../../../pages/private/SearchTest'
import { message, QRCode } from 'antd'
import OrdersService from '../../../services/orders/OrdersService'
import userStore from '../../../stores/userStore'
import { _getOrdersData,  _getOrdersData2,  _tagStatus, _todayDate } from '../../../utils/functions'
import { Tag } from 'rsuite'
import { _handleAddproduct, _handleUpdateProduct } from '../../../utils/api/totaraApi'
import { debounce } from 'lodash'
import { errorType } from '../../../definitions/errorType'
import { usePDF, Margin } from 'react-to-pdf'

export function SearchPlanningModal({ SearchPlanningModalProps }: any) {
  const { showSearchPlanning, handleCloseSearchPlanning, searchPlanningProps } =
    SearchPlanningModalProps
  return (
    <Modal size='lg' show={showSearchPlanning} onHide={handleCloseSearchPlanning}>
      <SearchTest {...searchPlanningProps} />
      <Modal.Footer className='border-0 sticky-bottom justify-content-start'>
        <Button variant='secondary' className='' onClick={handleCloseSearchPlanning}>
        <i className='ri-close-line me-2' ></i>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export function DetailOrderModal({ detailOrderModalProps }: any) {
  const stockageData = ['CALE', 'PONTEE', 'REFRIGERE', 'CONGELE']

  const {
    show,
    selectedOrder,
    handleClose,
    setSelectedOrder,
    dataOrder,
    setDataOrder,
    setErrorOrderMessage,
    setIsLoading,
    currentPage,
    setTotalPages,
    setInfoOrder,
    toggleShowInfo,
  } = detailOrderModalProps

  const dataStore = userStore((state: any) => state)
  const [showUpdateProductModal, setShowUpdateProductModal] = React.useState<boolean>(false)

  const [isError, setIsError] = React.useState<errorType>({
    error: false,
    message: '',
  })
  const [sortedProductByCodeSH, setSortedProductByCodeSH] = React.useState<any>()
  const [selectedProduct, setSelectedProduct] = React.useState<any>()
  const [filteringData, setFilteringData] = React.useState<any>({
    detail_referenceExterne: '',
    detail_description: '',
    detail_nbColis: '',
    detail_poids: '',
    detail_stockage: '',
    detail_codeTarif: '',
    detail_codeSH: '',
  })

  const handleCloseUpdateProductModal = () => {
    setShowUpdateProductModal(false)
    setSelectedProduct({})
  }
  const handleShowUpdateProductModal = () => {
    setIsError({
      error: false,
      message: '',
    })
    setShowUpdateProductModal(true)
  }

  const [showAddProductModal, setShowAddProductModal] = React.useState<boolean>(false)

  const handleCloseAddProductModal = () => {
    setShowAddProductModal(false)
  }
  //Bloquer : Ajout produit à une commande existante
  // const handleShowAddProductModal = () => {
  //   setShowAddProductModal(true)
  //   setSelectedProduct({})
  // }

  React.useEffect(() => {
    setSortedProductByCodeSH(
      selectedOrder?.items?.sort((a: any, b: any) => a?.codeSH?.localeCompare(b?.codeSH))
    )
  }, [selectedOrder])

  //Editer une ligne de commande
  const handleSaveProductUpdates = (e: any) => {
    e.preventDefault()
    const orderData = {
      detail_nbColis: selectedProduct.detail_nbColis,
      detail_description: selectedProduct.detail_description,
      detail_codeSH: selectedProduct.detail_codeSH,
      detail_codeTarif: selectedProduct.detail_codeTarif,
      detail_stockage: selectedProduct.detail_stockage,
      detail_poids: selectedProduct.detail_poids,
      detail_referenceExterne: selectedProduct.detail_referenceExterne,
    }
    // Tableau d'origine
    const updatedProducts = selectedOrder.items.map((product: any) => {
      // Si l'ID du produit correspond à celui sélectionné, on le met à jour
      if (product?.detail_referenceExterne === selectedProduct?.detail_referenceExterne) {
        return {
          ...product,
          detail_nbColis: selectedProduct.detail_nbColis,
          detail_description: selectedProduct.detail_description,
          detail_codeSH: selectedProduct.detail_codeSH,
          detail_codeTarif: selectedProduct.detail_codeTarif,
          detail_stockage: selectedProduct.detail_stockage,
          detail_poids: selectedProduct.detail_poids,
          detail_referenceExterne: selectedProduct.detail_referenceExterne, // On applique les changements
        }
      }
      return product // On retourne les autres produits inchangés
    })

    // Mise à jour de l'état selectedOrder avec les produits mis à jour
    setSelectedOrder((prevOrder: any) => ({
      ...prevOrder,
      items: updatedProducts,
    }))

    const updatedOrder = dataOrder?.map((order: any, indx: number) => {
      if (order.id === selectedOrder?.id) {
        return {
          ...order,
          selectedOrder,
        }
      }
      return order
    })

    setDataOrder(updatedOrder)

    //Applique les changement en base de donnée
    _handleUpdateProduct(
      dataStore?.token,
      orderData,
      selectedProduct.id,
      setIsError,
      handleCloseUpdateProductModal
    )
    _getOrdersData2(   dataStore?.token,
      currentPage,
      setDataOrder,
      setTotalPages,
      setIsLoading,
      setErrorOrderMessage)
    // Fermer le modal
  }

  //Ajouter un produit à une commande existante
  const handleSaveProductAdd = (e: any) => {
    e.preventDefault()
    const orderData = {
      id_order: selectedOrder.id,
      detail_nbColis: selectedProduct.detail_nbColis,
      detail_description: selectedProduct.detail_description,
      detail_codeSH: selectedProduct.detail_codeSH,
      detail_codeTarif: selectedProduct.detail_codeTarif,
      detail_stockage: selectedProduct.detail_stockage,
      detail_poids: selectedProduct.detail_poids,
      detail_unitePoids: 'KILO',
      detail_referenceExterne: selectedProduct.detail_referenceExterne,
    }

    const newTab = [...selectedOrder.items, orderData]

    setSelectedOrder((prevOrder: any) => ({
      ...prevOrder,
      items: newTab,
    }))

    _handleAddproduct(dataStore?.token, orderData)
    _getOrdersData(dataStore?.token, setDataOrder, setIsLoading, setErrorOrderMessage)
    handleCloseAddProductModal()
  }

  //Fitrer detail produit

  const handlefilteredProduct = (e: any) => {
    const { name, value } = e.currentTarget
    const updatedFilteringData = {
      ...filteringData,
      [name]: value,
    }
    setFilteringData(updatedFilteringData)
    debouncedFilter(updatedFilteringData)
  }

  const debouncedFilter = debounce((updatedFilteringData) => {
    filteredProduct(dataStore.token, updatedFilteringData, selectedOrder?.id)
  }, 500) // 300ms delay

  const filteredProduct = async (token: any, filteringData: any, id: number) => {
    const isEmpty =
      filteringData?.detail_codeSH === '' &&
      filteringData?.detail_codeTarif === '' &&
      filteringData?.detail_description === '' &&
      filteringData?.detail_nbColis === '' &&
      filteringData?.detail_poids === '' &&
      filteringData?.detail_referenceExterne === '' &&
      filteringData?.detail_stockage === '' &&
      filteringData?.detail_unitePoids === '' &&
      filteringData?.id === '' &&
      filteringData?.id_order === ''
    try {
      const response = await OrdersService.filteredItem(token, filteringData, id)
      if (response.data.data.length > 0 && !isEmpty) {
        const dataz = response.data.data?.filter((prod: any) => {
          return (
            prod.detail_codeSH,
            prod.detail_codeTarif,
            prod.detail_description,
            prod.detail_nbColis,
            prod.detail_poids,
            prod.detail_referenceExterne,
            prod.detail_stockage,
            prod.detail_unitePoids,
            prod.id,
            prod.id_order
          )
        })
        setSortedProductByCodeSH(dataz)
      } else {
        setSortedProductByCodeSH(
          selectedOrder?.items?.sort((a: any, b: any) => a?.codeSH?.localeCompare(b?.codeSH))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Modal size='lg' show={show} onHide={handleClose} className='px-0'>
        <Modal.Header className='text-center'>
          <Container fluid>
            <Row>
              <Col xs={12} className='m-auto responsive-font-medium  mb-3'>
                {selectedOrder?.destinataire?.denomination}
              </Col>

              <Col xs={4} className='m-auto text-start responsive-font-small'>
                N° Facture: {selectedOrder?.referenceHorsRevatua}
              </Col>
              <Col xs={4} className='m-auto responsive-font-medium mb-3'>
                {/* <strong>{selectedOrder?.statusRevatua}</strong> */}
                <Tag color={_tagStatus(selectedOrder?.statusRevatua)} className='mb-3'>
                  {selectedOrder?.statusRevatua}
                </Tag>
                <div>
                  <b>Paiement : </b>
                  {selectedOrder?.paiement}
                </div>
              </Col>
              <Col xs={4} className='m-auto text-end responsive-font-small'>
                Date: {selectedOrder?.date_creation}
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {/* <Button
              variant='secondary'
              className='mb-3'
              onClick={() => {
                if (
                  selectedOrder?.statusRevatua !== '"A_PLANIFIER"' &&
                  selectedOrder?.statusRevatua !== 'A_PLANIFIER' &&
                  selectedOrder?.statusRevatua !== 'BROUILLON'
                ) {
                  alert("Cette commande n'est plus modifiable")
                } else {
                  handleShowAddProductModal()
                }
              }}
            >
              + Ajouter un produit
            </Button> */}
            <Table striped hover responsive className='border '>
              <thead className='responsive-font-medium'>
                <tr>
                  <th style={{ width: '' }}>Réf.</th>
                  <th style={{ width: '256px' }}>Désignation</th>
                  <th style={{ width: '' }}>Nb Colis</th>
                  <th style={{ width: '' }} className='text-end'>
                    Poids (Kg)
                  </th>
                  <th style={{ width: '' }}>Stockage</th>
                  <th style={{ width: '' }}>C.Tarif</th>
                  <th style={{ width: '' }} className='text-end'>
                    C.SH
                  </th>
                  <th style={{ width: '' }} className='text-center'>
                    Action
                  </th>
                </tr>
              </thead>
              <thead className='responsive-font-medium border-top-0'>
                <tr>
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='Référence'
                      name='detail_referenceExterne'
                      value={filteringData.detail_referenceExterne}
                      onChange={(e) => {
                        const detail_referenceExterne = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_referenceExterne: detail_referenceExterne,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='Désignation'
                      name='detail_description'
                      value={filteringData.detail_description}
                      onChange={handlefilteredProduct}
                    />
                  </th>
                  <th>
                    {' '}
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='nbColis'
                      name='detail_nbColis'
                      value={filteringData.detail_nbColis}
                      onChange={(e) => {
                        const detail_nbColis = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_nbColis: detail_nbColis,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>
                  <th className='text-end'>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='Poids'
                      name='detail_poids'
                      value={filteringData.detail_poids}
                      onChange={(e) => {
                        const detail_poids = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_poids: detail_poids,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='Stockage'
                      name='detail_stockage'
                      value={filteringData.detail_stockage}
                      onChange={(e) => {
                        const detail_stockage = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_stockage: detail_stockage,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='C.tarif'
                      name='detail_codeTarif'
                      value={filteringData.detail_codeTarif}
                      onChange={(e) => {
                        const detail_codeTarif = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_codeTarif: detail_codeTarif,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='C.SH'
                      name='detail_codeSH'
                      value={filteringData.detail_codeSH}
                      onChange={(e) => {
                        const detail_codeSH = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_codeSH: detail_codeSH,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>

                  <th className='text-center'></th>
                </tr>
              </thead>
              <tbody className='responsive-font-small'>
                {sortedProductByCodeSH?.map((product: any, indexProd: number) => {
                  const isNoPoids =
                    product?.detail_poids <= 0 || product?.detail_poids === undefined
                  return (
                    <tr key={indexProd}>
                      <td className={`text-${isNoPoids && 'danger'}`}>
                        {product?.detail_referenceExterne}
                      </td>
                      <td className={`text-${isNoPoids && 'danger'}`}>
                        {product?.detail_description}
                      </td>
                      <td className={`text-end text-${isNoPoids && 'danger'}`}>
                        {product?.detail_nbColis}
                      </td>
                      <td className={`text-end text-${isNoPoids && 'danger'}`}>
                        {product?.detail_poids}
                      </td>
                      <td className={`text-${isNoPoids && 'danger'}`}>
                        {product?.detail_stockage}
                      </td>
                      <td className={`text-${isNoPoids && 'danger'}`}>
                        {product?.detail_codeTarif}
                      </td>
                      <td className={`text-${isNoPoids && 'danger'}`}>
                        {product?.detail_codeSH}
                      </td>
                      <td
                        className='text-center pointer'
                        onClick={() => {
                          if (
                            selectedOrder?.statusRevatua === 'A_PLANIFIER' ||
                            selectedOrder?.statusRevatua === 'BROUILLON'
                          ) {
                            setSelectedProduct(product)
                            handleShowUpdateProductModal()
                          } else {
                                                    
                            setInfoOrder(
                              `Les commandes qui sont au statut ${selectedOrder?.statusRevatua} ne sont pas modifiable`
                            )
                            toggleShowInfo()
                          }
                        }}
                      >
                        <i className='ri-pencil-line fs-5'></i>{' '}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Container>
        </Modal.Body>
        <Modal.Footer className='sticky-bottom border-0'>
          <Button
            variant='secondary'
            onClick={() => {
              handleClose()
              setFilteringData({
                detail_description: '',
                detail_nbColis: '',
                detail_referenceExterne: '',
                detail_poids: '',
                detail_stockage: '',
                detail_codeTarif: '',
                detail_codeSH: '',
              })
              _getOrdersData2(
                dataStore?.token,
                currentPage,
                setDataOrder,
                setTotalPages,
                setIsLoading,
                setErrorOrderMessage
              )
            }}
          >
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Product 
      Modal bloqué
      */}
      <Modal size='lg' show={showAddProductModal} onHide={handleCloseAddProductModal}>
        <Form onSubmit={handleSaveProductAdd}>
          <Modal.Header closeButton>
            <Modal.Title>Ajouter un produit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12} sm={6}>
                <FloatingLabel controlId='Code Article' label='Code Article' className='mb-3'>
                  <Form.Control
                    placeholder='Code Article'
                    type='text'
                    value={selectedProduct?.detail_referenceExterne}
                    onChange={(e) => {
                      const referenceExterne = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_referenceExterne: referenceExterne,
                      }))
                    }}
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12} sm={6}>
                <FloatingLabel controlId='Désignation' label='Désignation' className='mb-3'>
                  <Form.Control
                    placeholder='Désignation'
                    type='text'
                    value={selectedProduct?.detail_description}
                    onChange={(e) => {
                      const description = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_description: description,
                      }))
                    }}
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12} sm={4}>
                <FloatingLabel controlId='Code Tarif' label='Code Tarif' className='mb-3'>
                  <Form.Control
                    placeholder='Code Tarif'
                    type='text'
                    value={selectedProduct?.detail_codeTarif}
                    onChange={(e) => {
                      const codeTarif = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_codeTarif: codeTarif,
                      }))
                    }}
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12} sm={4}>
                <FloatingLabel controlId='Code SH' label='Code SH' className='mb-3'>
                  <Form.Control
                    placeholder='Code SH'
                    type='text'
                    value={selectedProduct?.detail_codeSH}
                    onChange={(e) => {
                      const codeSH = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_codeSH: codeSH,
                      }))
                    }}
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12} sm={4}>
                <FloatingLabel controlId='Stockage' label='Stockage' className='mb-3'>
                  <Form.Select
                    name='stockage'
                    value={selectedProduct?.detail_stockage}
                    onChange={(e) => {
                      const stockage = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_stockage: stockage,
                      }))
                    }}
                    required
                  >
                    <option value='' className='text-ui-second'>
                      Choisir une zone de stockage
                    </option>
                    {stockageData?.map((stockage: any, index: any) => (
                      <option key={index} value={stockage}>
                        {stockage}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs={12} sm={6}>
                <FloatingLabel controlId='Qté' label='Qté' className='mb-3'>
                  <Form.Control
                    placeholder='Qté'
                    type='number'
                    value={selectedProduct?.detail_nbColis}
                    onChange={(e) => {
                      const qte = parseInt(e.currentTarget.value)
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_nbColis: qte,
                      }))
                    }}
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12} sm={6}>
                <FloatingLabel controlId='Poids' label='Poids (Kg)' className='mb-3'>
                  <Form.Control
                    placeholder='Poids (Kg)'
                    type='float'
                    min={0}
                    value={selectedProduct?.detail_poids}
                    onChange={(e) => {
                      const poids = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_poids: poids,
                      }))
                    }}
                    required
                  />
                </FloatingLabel>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseAddProductModal}>
              Annuler
            </Button>
            <Button variant='primary' type='submit'>
              Valider
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* Update product (qty & poids) */}
      <Modal show={showUpdateProductModal} onHide={handleCloseUpdateProductModal}>
        <Form onSubmit={handleSaveProductUpdates}>
          <Modal.Header className='border-bottom-0' closeButton>
            <Modal.Title>
              <i className='ri-pencil-line fs-4 text-primary'></i> Editer une ligne article
            </Modal.Title>
          </Modal.Header>
          <div className='my-2'>
            <Row className='font-85 w-100 gx-0'>
              <Col className='ps-3' xs={3}>
                <b>Code article</b>
              </Col>
              <Col className='text-center'>
                <b>Désignation</b>
              </Col>
              <Col className='text-end pe-3' xs={3}>
                <b>Stockage</b>
              </Col>
            </Row>
            <Row className='font-85 w-100 gx-0 mb-3'>
              <Col className='ps-3' xs={3}>
                {selectedProduct?.detail_referenceExterne}
              </Col>
              <Col className='text-center'>{selectedProduct?.detail_description}</Col>
              <Col className='text-end pe-3' xs={3}>
                {selectedProduct?.detail_stockage}
              </Col>
            </Row>
            <Row className='font-85 w-100 gx-0'>
              <Col className='ps-3' xs={6}>
                <b>Code tarif : </b>{selectedProduct?.detail_codeTarif}
              </Col>
              <Col className='text-end pe-3' xs={6}>
                <b>Code SH : </b>{selectedProduct?.detail_codeSH}
              </Col>
            </Row>
          </div>
          <Modal.Body>
            <Row>
              <Col xs={12}>
                <FloatingLabel controlId='nbcolis' label='nbcolis' className='mb-3'>
                  <Form.Control
                    placeholder='nbcolis'
                    type='number'
                    value={selectedProduct?.detail_nbColis || null}
                    onChange={(e) => {
                      const qte = parseInt(e.currentTarget.value)
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_nbColis: qte,
                      }))
                    }}
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12}>
                <FloatingLabel controlId='Poids' label='Poids (Kg)' className='mb-3'>
                  <Form.Control
                    placeholder='Poids (Kg)'
                    type='float'
                    min={0}
                    value={selectedProduct?.detail_poids || null}
                    onChange={(e) => {
                      const poids = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_poids: poids,
                      }))
                    }}
                    required
                  />
                </FloatingLabel>
              </Col>
              {/* <Col xs={12}>
                <FloatingLabel controlId='Stockage' label='Stockage' className='mb-3'>
                  <Form.Select
                    name='stockage'
                    value={selectedProduct?.detail_stockage}
                    onChange={(e) => {
                      const stockage = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_stockage: stockage,
                      }))
                    }}
                    required
                  >
                    <option value='' className='text-ui-second'>
                      Choisir une zone de stockage
                    </option>
                    {stockageData?.map((stockage: any, index: any) => (
                      <option key={index} value={stockage}>
                        {stockage}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Col> */}
              <Col xs={12} sm={6}>
                <FloatingLabel controlId='Code Tarif' label='Code Tarif' className='mb-3'>
                  <Form.Control
                    placeholder='Code Tarif'
                    type='text'
                    value={selectedProduct?.detail_codeTarif || ""}
                    onChange={(e) => {
                      const codeTarif = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_codeTarif: codeTarif,
                      }))
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12} sm={6}>
                <FloatingLabel controlId='Code SH' label='Code SH' className='mb-3'>
                  <Form.Control
                    placeholder='Code SH'
                    type='text'
                    value={selectedProduct?.detail_codeSH || ""}
                    onChange={(e) => {
                      const codeSH = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_codeSH: codeSH,
                      }))
                    }}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Alert
              show={isError?.error}
              variant='danger'
              className='d-flex align-items-center mt-3'
            >
              <i className='ri-error-warning-line fs-4 text-danger me-2'></i> {isError.message}
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseUpdateProductModal}>
              Annuler
            </Button>
            <Button variant='primary' type='submit'>
              Valider
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export function DetailConnaissementModal({ detailOrderModalProps }: any) {
  const { show, selectedConnaissement, handleClose, handlePrint, printRef } =
    detailOrderModalProps

    const nbreFacture = selectedConnaissement?.referenceHorsRevatua?.split("|")?.length

  const { toPDF, targetRef } = usePDF({
    method: 'save',
    filename: `${selectedConnaissement.numero}.pdf`,
    page: { margin: Margin.MEDIUM },
  })

  const totalWeight = selectedConnaissement?.detailConnaissements
    ?.reduce((acc: any, product: any) => acc + product.poids, 0)
    ?.toFixed(3)
  const totalColis = selectedConnaissement?.detailConnaissements?.reduce(
    (acc: any, product: any) => acc + product.nbColis,
    0
  )

  const presta = [
    {
      "version": "2024-09-27T23:20:34.384Z",
      "id": 0,
      "libelle": "camera surveillance",
      "actif": true
    },
    
      {
        "version": "2024-09-27T23:20:34.384Z",
        "id": 2,
        "libelle": "Emballage spcécial",
        "actif": true
      }
    
  ]


  return (
    <Modal size='lg' show={show} onHide={handleClose} className='px-0'>
      <Modal.Body className='text-center border-bottom-0 pdf-container printer' ref={printRef}>
          <div className="print-date text-start font-75 d-none">

          Imprimé le : {_todayDate()}
          </div>
        <div ref={targetRef} className='pdf-container'>
          <Container fluid>
            <Row className='mb-3'>
              <Col xs={12} md={12} className='m-auto responsive-font-medium'>
                <div>{selectedConnaissement?.voyage?.nomNavire}</div>
                <div className='font-75'>
                  BP {selectedConnaissement?.armateur?.boitePostale}{' '}
                  {selectedConnaissement?.armateur?.commune?.codePostal}{' '}
                  {selectedConnaissement?.armateur?.commune?.nom} <strong>Tél : </strong>{' '}
                  {selectedConnaissement?.armateur?.telephone}
                </div>
              </Col>
              <Col
                xs={4}
                md={4}
                className='d-flex justify-content-center py-2 py-md-0  text-md-end responsive-font-small'
              ></Col>
              <Col xs={4} md={4} className='py-2 py-md-0  text-center responsive-font-small'>
                <div className='mb-3'>
                  <strong>CONNAISSEMENT</strong>
                </div>
                <Tag
                  className='responsive-font-small'
                  size='sm'
                  color={_tagStatus(
                    selectedConnaissement?.dernierEtat?.evenementConnaissement
                  )}
                >
                  {selectedConnaissement?.dernierEtat?.evenementConnaissement}
                </Tag>
                {selectedConnaissement?.dernierEtat?.motif && (
                  <div>
                    <strong>Motif</strong> : {selectedConnaissement?.dernierEtat?.motif}
                  </div>
                )}
              </Col>
              <Col
                xs={4}
                md={4}
                className='d-flex justify-content-center py-2 py-md-0  text-md-end responsive-font-small'
              >
                {selectedConnaissement?.numero !== null && (
                  <QRCode
                    value={selectedConnaissement?.numero}
                    size={160}
                    bordered={true}
                    bgColor={'#ffffff'}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={4} className='m-auto text-start responsive-font-small'></Col>
              <Col xs={4} className='m-auto responsive-font-medium'></Col>
              <Col xs={4} className='m-auto text-end responsive-font-small'></Col>
            </Row>
            <Row className='responsive-font-small mb-3'>
              <Col xs={4} className='m-auto text-start responsive-font-small mb-3 '>
                Date Départ :{' '}
                {new Date(selectedConnaissement.voyage?.dateDepart).toLocaleDateString(
                  'fr-FR',
                  {
                    timeZone: 'UTC',
                  }
                )}{' '}
                {selectedConnaissement.voyage?.heureDepart?.hour && selectedConnaissement.voyage?.heureDepart?.hour + " : "}
                {selectedConnaissement.voyage?.heureDepart?.minute}
              </Col>
              <Col xs={4} className='m-auto responsive-font-medium'></Col>
              <Col xs={4} className='m-auto text-end responsive-font-small'>
                {selectedConnaissement?.numero ? (
                  <span> N°: {selectedConnaissement?.numero} </span>
                ) : (
                  <span> id : {selectedConnaissement?.id}</span>
                )}
              </Col>
            </Row>
            <Container fluid className='mb-3'>
              <Row className='responsive-font-small mb-3 border'>
                <Col xs={4} className='m-auto text-start responsive-font-small'>
                  Navire :{' '}
                  {selectedConnaissement?.voyage?.periple &&
                    selectedConnaissement?.voyage?.periple[0]?.nomNavire}
                </Col>
                <Col xs={4} className='m-auto responsive-font-medium'>
                  Voyage n°: {selectedConnaissement?.voyage?.numero}
                </Col>
                <Col xs={4} className='m-auto text-end responsive-font-small'>
                  Départ: {selectedConnaissement?.voyage?.dateDepart}
                </Col>
              </Row>
              <Row className='responsive-font-small mb-3 border'>
                <Col xs={6} className='m-auto text-start responsive-font-small'>
                  <strong>Expéditeur</strong>:{' '}
                  {selectedConnaissement?.expediteur?.denomination} - N°Tahiti{' '}
                  {selectedConnaissement?.expediteur?.numeroTahiti}
                </Col>
                <Col xs={6} className='m-auto text-start responsive-font-small'>
                  <strong>N° Facture{nbreFacture >1 && `(${nbreFacture})`}</strong>: {selectedConnaissement?.referenceHorsRevatua}{' '}
                </Col>
                <Col xs={12} className='m-auto text-start responsive-font-small'>
                  <strong>Ile de départ</strong>: {selectedConnaissement?.voyage?.ileDepart}{' '}
                  PAPEETE
                </Col>
                <Col xs={6} className='m-auto text-start responsive-font-medium'>
                  <strong>Téléphone</strong>: {selectedConnaissement?.expediteur?.telephone}
                </Col>
                <Col xs={6} className='m-auto text-start responsive-font-small'>
                  <strong>Mail</strong>: {selectedConnaissement?.expediteur?.mail}
                </Col>
              </Row>
              <Row className='responsive-font-small border'>
                <Col xs={12} className='m-auto text-start responsive-font-small'>
                  <strong>Destinataire</strong>:{' '}
                  {selectedConnaissement?.destinataire?.denomination} - N°Tahiti{' '}
                  {selectedConnaissement?.destinataire?.numeroTahiti}
                </Col>
                <Col xs={12} className='m-auto text-start responsive-font-small'>
                  <strong>Ile de arrivée</strong>: {selectedConnaissement?.ileArrivee?.nom}
                </Col>
                <Col xs={6} className='m-auto text-start responsive-font-medium'>
                  <strong>Téléphone</strong>: {selectedConnaissement?.destinataire?.telephone}
                </Col>
                <Col xs={6} className='m-auto text-start responsive-font-small'>
                  <strong>Mail</strong>: {selectedConnaissement?.destinataire?.mail}
                </Col>
              </Row>
            </Container>
          </Container>
          <Container fluid>
            <Table striped hover responsive className='border'>
              <thead className='responsive-font-medium'>
                <tr>
                  <th>Désignation</th>
                  <th>Nb Colis</th>
                  <th className='text-end pe-3'>Volume</th>
                  <th className='text-end pe-3'>Poids(Kg)</th>
                </tr>
              </thead>
              <tbody className='responsive-font-small'>
                {selectedConnaissement?.detailConnaissements?.map(
                  (product: any, indexProd: number) => {
                    return (
                      <tr key={indexProd}>
                        <td>{product?.description}</td>
                        <td>{product?.nbColis}</td>
                        <td className='text-end pe-3'>{product?.volume}</td>
                        <td className='text-end pe-3'>{product?.poids}</td>
                      </tr>
                    )
                  }
                )}
                <tr>
                  {/* <td className='text- pe-3'>{selectedConnaissement?.nombreColisAEmbarquer}</td> */}
                  <td className='text-end'>Total </td>
                  <td className='text- pe-3'>{totalColis}</td>
                  <td className='text-end pe-3'></td>
                  <td className='text-end pe-3'>{totalWeight}</td>
                </tr>
              </tbody>
            </Table>
            <Container>
              <Row className='responsive-font-small'>
                <Col xs={4} className='m-auto text-end responsive-font-small'></Col>
                <Col xs={4} className='m-auto text-start responsive-font-small'>
                  Nombre de palettes : 
                </Col>
                <Col xs={4} className='m-auto text-start responsive-font-small'>
                  {selectedConnaissement?.nombreColisAEmbarquer}
                </Col>
              </Row>
              <Row className='responsive-font-small'>
                <Col xs={4} className='m-auto text-end responsive-font-small'></Col>
                <Col xs={4} className='m-auto text-start responsive-font-small'>
                  Mode de règlement : 
                </Col>
                <Col xs={4} className='m-auto text-start responsive-font-small'>
                  {selectedConnaissement?.paiement}
                </Col>
              </Row>
              <Row className='responsive-font-small'>
                <Col xs={4} className=' text-end responsive-font-small'></Col>
                <Col xs={4} className=' text-start responsive-font-small'>
                  Prestations complémentaires : 
                </Col>
                <Col xs={4} className='m-auto text-start responsive-font-small'>
                  {selectedConnaissement?.prestationsComplementaires?.length === 0
                    ? 'NON'
                    : <ul>
                    {presta?.map((prestation: any, index: number) => (
                      <li key={index}>{prestation?.libelle}</li>

                    ))}
                    </ul>}
                </Col>
              </Row>
            </Container>
            <Table responsive className='small-font'></Table>
          </Container>
        </div>
      </Modal.Body>
      <Modal.Footer className='sticky-bottom border-0'>
        {/* <Button className='print-button' variant='success' onClick={() => handlePrint()}>
          <i className='ri-printer-fill'></i> Imprimer
        </Button>
        <Button
          className='print-button'
          variant='warning'
          onClick={() => toPDF()}
        >
          <i className='ri-download-2-fill'></i> Télécharger
        </Button> */}
        <Button className='print-button' variant='secondary' onClick={handleClose}>
          <i className='ri-close-cirlce-fill'></i> Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
