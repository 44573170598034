import React from 'react'
import {
  Card,
  Form,
  Container,
  Alert,
  InputGroup,
  Button,
  Spinner,
  Image,
} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import tots from '../../styles/images/remora.png'
import AuthService from '../../services/Auth/AuthService'
import UserService from '../../services/user/UserService'
import userStore from '../../stores/userStore'
import { _handleClearCache } from '../../utils/functions'

export default function Auth() {
  const authLogin = userStore((state: any) => state.authLogin)
  const dataStore = userStore((state: any) => state)

  const navigate = useNavigate()

  const [isView, setIsView] = React.useState<boolean>(false)
  const [isError, setIsError] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [token, setToken] = React.useState<string>('')
  const [formData, setFormData] = React.useState<any>({
    email: '',
    password: '',
  })

  React.useEffect(() => {
    if (dataStore.token && dataStore.token?.length > 0) {
      navigate('/')
    }
  }, [])

  React.useEffect(() => {
    if (token && token?.length > 0) {
      getMe(token)
      _handleClearCache()
    }
  }, [token])



  const handleSubmit = (e: any) => {
    e.preventDefault()
    authentificationHandler(formData)
  }

  const getMe = async (token: string) => {
    try {
      const response = await UserService.getMe(token)
      if (response) {
        const access_token = response?.data?.data?.companies && response?.data?.data?.companies[0]?.access_token
        authLogin(
          response.data.data.id,
          response.data.data.name,
          response.data.data.role,
          response.data.data.email,
          token,
          response?.data?.data?.companies,
          response?.data?.data?.company?.id_company,
          response?.data?.data?.company?.name,
          response?.data?.data?.company?.slug,
          access_token
        )
        navigate('/')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const authentificationHandler = async (data: any) => {
    setIsLoading(true)
    setIsError(false)
    try {
      const response = await AuthService.loginTotara(data)
      setToken(response.data.access_token)
      setIsError(false)
      setIsLoading(false)
    } catch (error: any) {
      setIsError(true)
      setIsLoading(false)
      const messageError = error?.response?.data?.message ? error?.response?.data?.message : error?.message === "Network Error" ? "Oups! une Erreur est survenue, contactez votre administrateur" : error?.message  
      setErrorMessage(messageError)
      console.log(error) 
    }
  }

  return (
    <Container fluid className='d-flex justify-content-center align-items-center vh-100 w-100'>
      <Container fluid className='col-10 col-md-5 col-lg-4 col-xl-4 px-0 '>
        <Card className='  animate__animated animate__fadeI rounded border-seondary'>
          <Card.Title className='d-md-flex justify-content-center align-item-center my-3 text-center text-secondary'>
            <Image src={tots} width={80} alt='logo' className='me-md-3 ' />
          </Card.Title>
          <Card.Body className=''>
            <div className='logo-app text-center text-light animate__animated animate__rotateI'></div>
            <Form className='auth-form' onSubmit={handleSubmit}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label className='d-none'>Email</Form.Label>
                <InputGroup className='mb-4'>
                  <InputGroup.Text id='basic-addon1' className='bg-transparent  border'>
                    <i className='ri-user-fill text-secondary'></i>
                  </InputGroup.Text>
                  <Form.Control
                    className=' border'
                    type='email'
                    autoComplete='on'
                    placeholder='Email'
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    required
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <Form.Label className='d-none'>Mot de passe</Form.Label>
                <InputGroup className='mb-3'>
                  <InputGroup.Text id='basic-addon2' className='bg-transparent  border'>
                    <i className='ri-lock-2-fill text-secondary'></i>
                  </InputGroup.Text>
                  <Form.Control
                    className=' border border-end-0'
                    type={!isView ? 'password' : 'text'}
                    placeholder='Mot de passe'
                    value={formData.password}
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    required
                  />
                  <InputGroup.Text
                    id='eyeOrNot'
                    className='bg-transparent border border-start-0'
                    onClick={() => setIsView(!isView)}
                  >
                    {' '}
                    <i className={`ri-${!isView ? 'eye-off' : 'eye'}-fill text-secondary`}></i>
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              {isError && (
                <Alert variant='danger' className='d-flex align-items-center mt-2 py-0 m-auto'>
                  <i className='ri-close-circle-line fs-4 text-danger me-2'></i>
                  {errorMessage}
                </Alert>
              )}
              <Button type='submit' className='rounded  w-100 py-2 mt-4 ' variant='primary'>
                {isLoading ? (
                  <>
                    <Spinner variant='light' size='sm' /> Loading
                  </>
                ) : (
                  <>
                    <i className='ri-login-box-line'></i> Se connecter
                  </>
                )}
              </Button>
            </Form>
            {/* <div className='d-flex justify-content-center my-4 m-auto'>
              <hr className='w-50 mx-2' />
              <span>ou</span>
              <hr className='w-50 mx-2' />
            </div>
            <Button className='rounded  w-100 py-2  ' variant='light'>
              continuer avec Github <i className='ri-github-fill'></i>
            </Button> */}
          </Card.Body>
        </Card>
      </Container>
    </Container>
  )
}
