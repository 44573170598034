import React from 'react'
import {
  Alert,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Row,
  Spinner,
  Tab,
  Table,
  Tabs,
} from 'react-bootstrap'
import navireData from '../../data/navires/navires.json'
import {
  _detailProduct,
  _formatDate,
  _idOrders,
  _numFacture,
} from '../../utils/functions'
import {
  _getIslandByName,
  _getPlanningMooz,
  _getPlanningOfNavire,
  _getTrajetByIslandId,
  _refreshToken,
} from '../../utils/api/apiControlerFunctions'
import { DatePicker, InputPicker } from 'rsuite'
import userStore from '../../stores/userStore'
import OrdersService from '../../services/orders/OrdersService'
import ConnaissementServices from '../../services/connaissements/ConnaissementServices'
import { errorType } from '../../definitions/errorType'
const data = navireData.map((item) => ({ label: item.name, value: item.id }))

export default function SearchTest(searchPlanningProps: any) {
  const {
    ordersForConnaissement,
    setOrdersForConnaissement,
    handleCloseSearchPlanning,
    toggleShowA,
    toggleShowBrouillon,
    dataOrder,
    setDataOrder,
  } = searchPlanningProps

  const dataStore = userStore((state: any) => state)

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isLoadingBrouillon, setIsLoadingBrouillon] = React.useState<boolean>(false)
  const [isLoadingDemande, setIsLoadingDemande] = React.useState<boolean>(false)
  const [isError, setIsError] = React.useState<errorType>({
    error: false,
    message: '',
  })

  const [connaissementBrouillon, setConnaissementBrouillon] = React.useState<any>({
    numeroVoyage: '',
    paiement: ordersForConnaissement && ordersForConnaissement[0]?.paiement,
    ileDepart: 'Tahiti',
    lieuDepart: 'PAPEETE',
    statusRevatua:
      ordersForConnaissement && ordersForConnaissement[0]?.expediteur?.statusRevatua,
    expediteur: {
      // telephone: "40500220",
      // mail: "revarua.wand@lwane.com",
      denomination: "LOGIS",
      telephone: "40500220",
      mail: "test@mail.pf",
      numeroTahiti: "A35508"
      // denomination: ordersForConnaissement && ordersForConnaissement[0]?.expediteur?.denomination,
      // telephone: ordersForConnaissement && ordersForConnaissement[0]?.expediteur?.telephone,
      // mail: ordersForConnaissement && ordersForConnaissement[0]?.expediteur?.mail,
      // numeroTahiti: ordersForConnaissement && ordersForConnaissement[0]?.expediteur?.numeroTahiti,
    },
    destinataire: {
      denomination:
        ordersForConnaissement && ordersForConnaissement[0]?.destinataire?.denomination,
      telephone: ordersForConnaissement && ordersForConnaissement[0]?.destinataire?.telephone,
      mail: ordersForConnaissement && ordersForConnaissement[0]?.destinataire?.mail,
      numeroTahiti:
        ordersForConnaissement && ordersForConnaissement[0]?.destinataire?.numeroTahiti,
    },
    ileArrivee: ordersForConnaissement && ordersForConnaissement[0]?.ileArrivee,
    lieuArrivee: ordersForConnaissement && ordersForConnaissement[0]?.ileArrivee,
    detailConnaissementDTO: _detailProduct(ordersForConnaissement),
    referenceHorsRevatua: _numFacture(ordersForConnaissement),
    nombreColisAEmbarquer: null,
    volumeAEmbarquer: null,
    demandeParArmateur: false,
  })
  const [searchIslandName, setSearchIslandName] = React.useState<string>('')
  const [debouncedValue, setDebouncedValue] = React.useState(searchIslandName)
  const [listIsland, setListIsland] = React.useState<any>([])
  const [idOrderForConnaiss, setIdOrderForConnaiss] = React.useState<any>()
  const [key, setKey] = React.useState<string>("navires")

  const [isCobiaOrDory, setIsCobiaOrDory] = React.useState<boolean>(false)
  const [isErrorDate, setIsErrorDate] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string>('')

  const [trajetIslandData, setTrajetIslandData] = React.useState<any>()
  const [selectedTrajet, setSelectedTrajet] = React.useState<any>()
  const [searchIslandData, setSearchIslandData] = React.useState<any>({
    idNavire: '',
    nameNavire: '',
    page: '0',
    limit: '30',
    dateDebut: '',
    dateFin: '',
    ileArrivee: '',
    nameIleArrivee: '',
  })
  const [trajetData, setTrajetData] = React.useState<any>()
  const [searchNavireData, setSearchNavireData] = React.useState<any>({
    idNavire: '',
    nameNavire: '',
    page: '0',
    limit: '30',
    dateDebut: '',
    dateFin: '',
    ileArrivee: '',
  })


  React.useEffect(() => {
    setIdOrderForConnaiss(_idOrders(ordersForConnaissement))
  }, [connaissementBrouillon, ordersForConnaissement])

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchIslandName)
    }, 500)

    return () => {
      clearTimeout(handler)
    }
  }, [searchIslandName])

  React.useEffect(() => {
    if (searchIslandName !== '') {
      _getIslandByName(searchIslandName, setListIsland)
    } else {
      setListIsland([])
    }
  }, [debouncedValue, searchIslandName])

  React.useEffect(() => {
    if (searchNavireData.idNavire === '4' || searchNavireData.idNavire === '5') {
      setIsCobiaOrDory(true)
    } else {
      setIsCobiaOrDory(false)
    }
  }, [searchNavireData])

  // update order status in local
  const editOrderInDbNLocal = (connaissementBrouillon: any, numeroVoyage: any, id: number, status: string) => {

    const updatedData = {
      id_connaissement: id,
      numeroVoyage: numeroVoyage,
      statut_revatua: status,
    }
    
    const factures = connaissementBrouillon?.referenceHorsRevatua?.split('|') || []
    const updatedDataOrder = dataOrder.map((order: any) => {
      if (factures.includes(order.referenceHorsRevatua)) {
      
        return {
          ...order,
          id_connaissement: id,
          numeroVoyage: numeroVoyage,
          statusRevatua: status,
        }
      }
      return order
    })

    //mise à jour en BDD
    handleUpdateOrder(dataStore.token, updatedData)
    // Mise à jour local
    setDataOrder(updatedDataOrder)
  }

  // Post brouillon connaissement Revatua
  const handlePostBrouillon = async (status: string) => {

    setIsLoadingBrouillon(true)
    setIsError({
      error: false,
      message: '',
    })
    try {
      const response = await ConnaissementServices.postBrouillonConnaissement(
        dataStore.access_token,
        connaissementBrouillon
      )

      if (response.status === 201) {
        setIsLoadingBrouillon(false)
        setIsError({
          error: false,
          message: '',
        })
        editOrderInDbNLocal(
          connaissementBrouillon,
          selectedTrajet.numeroVoyage,
          response.data?.id,
          status
        )

        handleCloseSearchPlanning()
        toggleShowBrouillon()
        setOrdersForConnaissement([])
      }
    } catch (error: any) {
      console.log(error)
      setIsLoadingBrouillon(false)
      setIsError({
        error: true,
        message: error?.response?.data?.message,
      })
      if (error?.response?.data?.error === 'invalid_token') {
        const idCompany = dataStore?.company && dataStore?.company[0]?.id_company
        _refreshToken(dataStore?.token, idCompany)
      }
    }
  }

  //Post demande connaissement Revatua
  const handlePostDemande = async (status: string) => {
    setIsLoadingDemande(true)
    setIsError({
      error: false,
      message: '',
    })
    try {
      const response = await ConnaissementServices.postDemandeConnaissement(
        dataStore.access_token,
        connaissementBrouillon
      )
      if (response.status === 201) {
        editOrderInDbNLocal(
          connaissementBrouillon,
          selectedTrajet.numeroVoyage,
          response.data?.id,
          status
        )

        handleCloseSearchPlanning()
        toggleShowBrouillon()
        setOrdersForConnaissement([])
        setIsLoadingDemande(false)
      }
    } catch (error: any) {
      console.log(error)
      setIsLoadingDemande(false)
      setIsError({
        error: true,
        message: error?.response?.data?.message,
      })
    }
  }

  const handleSelectTrajetIsland: any = (
    trajetIslandData: any,
    data: any,
    setConnaissementBrouillon: any,
    connaissementBrouillon: any
  ) => {
    // const filteredData = trajetIslandData?.filter(
    //   (navire: any) =>
    //     navire.numeroVoyage === data.numeroVoyage &&
    //     (navire.destinationDepart === 'Tahiti' ||
    //       navire.destinationDepart === 'Tahiti/PAPEETE')
    // )[0]
    // if (filteredData) {
    const [ileArrivee, lieu]: any = data?.destination?.split('/')
    setSelectedTrajet({
      numeroVoyage: data.numeroVoyage,
      nomNavire: data.nomNavire,
      abreviationNavire: data.abreviationNavire,
      // archipelDestinationDepart: filteredData?.archipelDestinationDepart,
      archipelDestinationArrivee: data.archipelDestinationArrivee,
      destinationDepart: 'Tahiti',
      dateDepart: data.dateDepart,
      heureDepart: data.heureDepart,
      destinationArrivee: data.ileDestination,
      dateArrivee: data.dateArrivee,
      heureArrivee: data.heureArrivee,
      // dateDepartVoyage: data.dateDepartVoyage,
      // dateRetourVoyage: data.dateRetourVoyage,
      // croisiere: data.croisiere,
      // codeZoneTarifaireArrivee: 'data.codeZoneTarifaireArrivee',
    })
    setConnaissementBrouillon({
      ...connaissementBrouillon,
      ileArrivee: data.ileDestination,
      lieuArrivee: data.lieuDestination,
      numeroVoyage: data.numeroVoyage,
    })

    setTrajetIslandData({})

    // else {
    //   toggleShowA()
    // }
  }

  const handleSelectTrajet: any = (
    trajetData: any,
    data: any,
    setConnaissementBrouillon: any,
    connaissementBrouillon: any
  ) => {
    const filteredData = trajetData?.filter(
      (navire: any) => navire.numeroVoyage === data.numeroVoyage
      // &&
      //   (navire.destinationDepart === 'Tahiti' ||
      //     navire.destinationDepart === 'Tahiti/PAPEETE')
    )[0]

    if (filteredData) {
      const [ileArrivee, lieu]: any = data?.destinationArrivee?.split('/')
      setSelectedTrajet({
        id: data.id,
        numeroVoyage: data.numeroVoyage,
        nomNavire: data.nomNavire,
        abreviationNavire: data.abreviationNavire,
        archipelDestinationDepart: filteredData?.archipelDestinationDepart,
        archipelDestinationArrivee: data.archipelDestinationArrivee,
        destinationDepart: filteredData.destinationDepart,
        dateDepart: filteredData.dateDepart,
        heureDepart: filteredData.heureDepart,
        destinationArrivee: data.destinationArrivee,
        dateArrivee: data.dateArrivee,
        heureArrivee: data.heureArrivee,
        dateDepartVoyage: data.dateDepartVoyage,
        dateRetourVoyage: data.dateRetourVoyage,
        croisiere: data.croisiere,
        codeZoneTarifaireArrivee: data.codeZoneTarifaireArrivee,
      })
      setConnaissementBrouillon({
        ...connaissementBrouillon,
        ileArrivee: ileArrivee,
        lieuArrivee: lieu ? lieu : '',
        numeroVoyage: data.numeroVoyage,
      })
      setTrajetData({})
    }
    // else {
    //   toggleShowA()
    // }
  }

  const handleDateChange = (value: any) => {
    setErrorMessage('')
    setIsErrorDate(false)
    if (value !== null) {
      const dateDebut = new Date(
        Date.UTC(value.getFullYear(), value.getMonth(), value.getDate())
      ).toLocaleDateString('fr-FR', { timeZone: 'UTC' })
      const selectedDate = new Date(value)
      const [day, month, year] = dateDebut?.split('/')

      const formattedDate = `${year}-${month}-${day}`
      // const selectedDate = new Date(`${dateDebut}`);
      const currentDate = new Date()

      // Remettre les heures à 00:00:00 pour ne comparer que les jours
      selectedDate.setHours(0, 0, 0, 0)
      currentDate.setHours(0, 0, 0, 0)

      if (selectedDate < currentDate) {
        setIsErrorDate(true)
        setErrorMessage('La date choisie ne peut pas être inférieure à la date du jour.')
      } else {
        setIsErrorDate(false)
        setErrorMessage('')
        setSearchNavireData({ ...searchNavireData, dateDebut: formattedDate })
      }
    }
  }

  //Update order in DB
  const handleUpdateOrder = async (token: string, orderData: any) => {
  
    try {
      const response = idOrderForConnaiss?.map((id: number) => {
        return OrdersService.updateOrder(token, orderData, id)
      })
    } catch (error) {
      console.log(error)
    }

  }

  //////si trajet selectionné
  const isTrajetOrSelectedData = trajetData !== undefined || selectedTrajet !== undefined
  const isTrajetIslandOrSelectedData =
    trajetIslandData !== undefined || selectedTrajet !== undefined

    console.log(connaissementBrouillon)

  return (
    <div className='p-3'>
      <div className='text-center'>
        <h5>
          {ordersForConnaissement && ordersForConnaissement[0]?.destinataire?.denomination}
        </h5>
      </div>
      <Tabs
        activeKey={key}
        onSelect={(k: any) => setKey(k)}
        id='schudleTab'
        className='mb-3'
        transition={true}
      >
        <Tab onClick={() => {}} eventKey='navires' title='Navires'>
          <Row>
            <Col>
              <Form.Group className='mb-3 px-2'>
                <Form.Label className='d-flex ' htlmfor='navire'>
                  Navire
                </Form.Label>
                <InputPicker
                  id='navire'
                  data={data}
                  style={{ width: 224 }}
                  className='text-dark'
                  placeholder='Sélectionnez un navire'
                  onChange={(value: any) => {
                    const idOfZeNavire = value
                    setSearchNavireData({ ...searchNavireData, idNavire: idOfZeNavire })
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3 px-2'>
                <Form.Label className='d-flex'>Période du</Form.Label>
                <DatePicker
                  id='start'
                  oneTap
                  format='dd-MM-yyyy'
                  placeholder='Date de début'
                  style={{ width: 'auto' }}
                  onChange={handleDateChange}
                />
                {errorMessage && (
                  <div style={{ position: 'absolute', color: 'red', marginTop: '8px' }}>
                    {errorMessage}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3 px-2'>
                <Form.Label className='d-flex'> au</Form.Label>
                <DatePicker
                  id='end'
                  oneTap
                  format='dd-MM-yyyy'
                  placeholder='Date de fin'
                  style={{ width: 'auto' }}
                  onChange={(value: any) => {
                    if (value !== null) {
                      const dateDebut = value?.toLocaleDateString('fr-FR')
                      const [day, month, year]: any = dateDebut?.split('/')
                      const formattedDate = `${year}-${month}-${day}`
                      setSearchNavireData({ ...searchNavireData, dateFin: formattedDate })
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Alert
            show={isCobiaOrDory}
            variant='danger'
            className='d-flex align-items-center py-2'
            style={{ marginTop: '10px' }}
          >
            <i className='ri-error-warning-line fs-4 me-2'> </i> Attention, ce bateau applique
            des conditions de validation de connaissance qui lui sont propres
          </Alert>
          {/* <Form.Group className='mb-3 px-2'>
            <Form.Label className='d-flex'>Nombre de palette à charger</Form.Label>
            <Form.Control
              id='nombrePalette'
              name='nombrePalette'
              type='number'
              placeholder='nombre de palette à charger'
              style={{ width: 'auto' }}
              onChange={(e: any) => {
                const nb = parseInt(e.currentTarget.value)
                setConnaissementBrouillon({
                  ...connaissementBrouillon,
                  nombreColisAEmbarquer: nb,
                })
              }}
            />
          </Form.Group> */}
          <Container fluid className='text-end px-0'>
            <Button
              variant='warning'
              disabled={isErrorDate}
              className='mb-3'
              onClick={() => {
                if (!isErrorDate) {
                  if (
                    searchNavireData?.idNavire === '24' ||
                    searchNavireData?.idNavire === '55' ||
                    searchNavireData?.idNavire === '3' ||
                    searchNavireData?.idNavire === '26'
                  ) {
                    _getPlanningMooz(
                      32,
                      // searchNavireData?.idNavire,
                      searchNavireData?.page,
                      searchNavireData?.limit,
                      searchNavireData?.dateDebut,
                      searchNavireData?.dateFin,
                      setSelectedTrajet,
                      setTrajetData,
                      setIsLoading,
                      setIsError

                    )
                  } else {
                    _getPlanningOfNavire(
                      searchNavireData?.idNavire,
                      searchNavireData?.page,
                      searchNavireData?.limit,
                      searchNavireData?.dateDebut,
                      searchNavireData?.dateFin,
                      setSelectedTrajet,
                      setTrajetData,
                      setIsLoading,
                      setIsError,
                    )
                  }
                }
              }}
            >
              {isLoading ? (
                <span className='text-light'>
                  <Spinner size='sm' variant='light' /> Rechercher
                </span>
              ) : (
                <span className='text-light'>Rechercher</span>
              )}
            </Button>
          </Container>

          {isTrajetOrSelectedData && (
            <React.Fragment>
              <div>Résultat</div>
              <Table striped hover responsive className=''>
                <thead>
                  <tr className='responsive-font-small text-center'>
                    <th></th>
                    <th>Départ</th>
                    <th className='border-end'></th>
                    <th></th>
                    <th>Arrivée</th>
                    <th className='border-end'></th>
                    <th></th>
                  </tr>
                </thead>
                <thead>
                  <tr className='responsive-font-small text-center'>
                    <th className=''>Date</th>
                    <th className=''>Heure</th>
                    <th className=' border-end'>Lieu</th>
                    <th className=''>Date</th>
                    <th className=''>Heure</th>
                    <th className=' border-end'>Lieu</th>
                    <th>N°voyage</th>
                  </tr>
                </thead>
                <tbody className='responsive-font-small text-center'>
                  {selectedTrajet === undefined ? (
                    trajetData?.map((data: any, index: number) => {
                      const isDepartTahiti =
                        data?.destinationDepart === 'Tahiti/PAPEETE' ||
                        data?.destinationDepart === 'Tahiti'
                      const isArriveeTahiti =
                        data?.destinationArrivee === 'Tahiti/PAPEETE' ||
                        data?.destinationArrivee === 'Tahiti'

                      return (
                        <tr
                          key={index}
                          className={'py-3 ' + (isArriveeTahiti ? 'not-allowed' : 'pointer')}
                          onClick={() => {
                            if (
                              data?.destinationArrivee !== 'Tahiti' &&
                              data?.destinationArrivee !== 'Tahiti/PAPEETE'
                            ) {
                              handleSelectTrajet(
                                trajetData,
                                data,
                                setConnaissementBrouillon,
                                connaissementBrouillon
                              )
                            } else {
                              alert(
                                'Attention, vous essayez de sélectionner un voyage de retour sur Tahiti'
                              )
                            }
                          }}
                        >
                          <td
                            className={'p-1 pe-0 p-md-2 ' + (isDepartTahiti && 'text-primary')}
                          >
                            {_formatDate(data?.dateDepart)}
                          </td>
                          <td
                            className={'p-1 pe-0 p-md-2 ' + (isDepartTahiti && 'text-primary')}
                          >
                            {data?.heureDepart}
                          </td>
                          <td
                            className={'p-1 pe-0 p-md-2 ' + (isDepartTahiti && 'text-primary')}
                          >
                            {data?.destinationDepart}
                          </td>
                          <td
                            className={'p-1 pe-0 p-md-2 ' + (isDepartTahiti && 'text-primary')}
                          >
                            {_formatDate(data?.dateArrivee)}
                          </td>
                          <td
                            className={'p-1 pe-0 p-md-2 ' + (isDepartTahiti && 'text-primary')}
                          >
                            {data?.heureArrivee}
                          </td>
                          <td
                            className={'p-1 pe-0 p-md-2 ' + (isDepartTahiti && 'text-primary')}
                          >
                            {' '}
                            {data?.destinationArrivee}
                          </td>
                          <td
                            className={'p-1 pe-0 p-md-2 ' + (isDepartTahiti && 'text-primary')}
                          >
                            {data?.numeroVoyage}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td>{_formatDate(selectedTrajet?.dateDepart)}</td>
                      <td>{selectedTrajet?.heureDepart}</td>
                      <td>{selectedTrajet?.destinationDepart}</td>
                      <td>{_formatDate(selectedTrajet?.dateArrivee)}</td>
                      <td>{selectedTrajet?.heureArrivee}</td>
                      <td> {selectedTrajet?.destinationArrivee}</td>
                      <td>{selectedTrajet?.numeroVoyage}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </React.Fragment>
          )}

          {selectedTrajet !== undefined && (
            <Container fluid className='text-end px-0'>
              <Button variant='secondary' onClick={() => handlePostBrouillon('BROUILLON')}>
              {isLoadingBrouillon ? (
                <span className='text-light'>
                  <Spinner size='sm' variant='light' /> Brouillon
                </span>
              ) : (
                <span className=''>Brouillon</span>
              )}
              </Button>{' '}
              <Button variant='success' onClick={() => handlePostDemande('DEMANDE')}>
              {isLoadingDemande ? (
                <span className='text-light'>
                  <Spinner size='sm' variant='light' /> Demande
                </span>
              ) : (
                <span className=''>Demande</span>
              )}
                
              </Button>
            </Container>
          )}
          <Alert show={isError?.error} variant='danger' className='mt-3'>
            <i className='ri-error-warning-line text-danger me-2 fs-4'></i>
            {isError?.message}
          </Alert>
        </Tab>
        <Tab onClick={() => {}} eventKey='iles' title="Ile d'arrivée">
          <Row>
            {/* Island input */}
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label className=''>Iles</Form.Label>
                <Dropdown as={InputGroup}>
                  <Dropdown.Toggle
                    variant='tranparent'
                    id='iles'
                    className='border-0 no-chevron p-0'
                  >
                    <InputGroup className=''>
                      <Form.Control
                        id='iles'
                        className='border'
                        name='iles'
                        type='text'
                        autoComplete='on'
                        placeholder='Saisissez votre île'
                        value={searchIslandName}
                        required
                        onChange={(e) => setSearchIslandName(e.target.value)}
                      />
                    </InputGroup>
                  </Dropdown.Toggle>
                  {listIsland?.length > 0 && (
                    <Dropdown.Menu align='end' show={listIsland?.length > 0 ? true : false}>
                      {listIsland?.map((island: any) => (
                        <Dropdown.Item
                          key={island?.id}
                          onClick={(e) => {
                            const ileArrivee = island?.id
                            setSearchIslandData({
                              ...searchIslandData,
                              ileArrivee: ileArrivee,
                              nameIleArrivee: searchIslandName,
                            })
                            setSearchIslandName(island?.nom)
                            setListIsland([])
                          }}
                        >
                          {' '}
                          {island.nom}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3 px-2'>
                <Form.Label className='d-flex'>Période du</Form.Label>
                <DatePicker
                  id='startDate'
                  oneTap
                  name='startDate'
                  format='dd-MM-yyyy'
                  placeholder='Date de début'
                  style={{ width: 'auto' }}
                  onChange={(value: any) => {
                    if (value !== null) {
                      const dateDebut = value?.toLocaleDateString('fr-FR')
                      const [day, month, year]: any = dateDebut?.split('/')
                      const formattedDate = `${year}-${month}-${day}`
                      setSearchIslandData({ ...searchIslandData, dateDebut: formattedDate })
                    }
                  }}
                  // onChange={handleDateChange}
                />
                {errorMessage && (
                  <div style={{ position: 'absolute', color: 'red', marginTop: '8px' }}>
                    {errorMessage}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3 px-2'>
                <Form.Label className='d-flex'> au</Form.Label>
                <DatePicker
                  id='endDate'
                  name='endDate'
                  oneTap
                  format='dd-MM-yyyy'
                  placeholder='Date de fin'
                  style={{ width: 'auto' }}
                  onChange={(value: any) => {
                    if (value !== null) {
                      const dateDebut = value?.toLocaleDateString('fr-FR')
                      const [day, month, year]: any = dateDebut?.split('/')
                      const formattedDate = `${year}-${month}-${day}`
                      setSearchIslandData({ ...searchIslandData, dateFin: formattedDate })
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* <Form.Group className='mb-3 px-2'>
            <Form.Label className='d-flex'>Nombre de palette à charger</Form.Label>
            <Form.Control
              id='nombrePalette'
              name='nombrePalette'
              type='number'
              placeholder='nombre de palette à charger'
              style={{ width: 'auto' }}
              onChange={(e: any) => {
                const nb = parseInt(e.currentTarget.value)
                setConnaissementBrouillon({
                  ...connaissementBrouillon,
                  nombreColisAEmbarquer: nb,
                })
              }}
            />
          </Form.Group> */}
          <Container fluid className='text-end px-0'>
            <Button
              variant='warning'
              disabled={isErrorDate}
              className='mb-3'
              onClick={() => {
                if (!isErrorDate) {
                  _getTrajetByIslandId(searchIslandData.ileArrivee,
                    0,
                    30,
                    searchIslandData.dateDebut,
                    searchIslandData.dateFin,
                    setIsLoading,
                    setIsError,
                    setTrajetIslandData
                  )
                
                }
              }}
            >
              {isLoading ? (
                <span className='text-light'>
                  <Spinner size='sm' variant='light' /> Rechercher
                </span>
              ) : (
                <span className='text-light'>Rechercher</span>
              )}
            </Button>
          </Container>

          {isTrajetIslandOrSelectedData && (
            <React.Fragment>
              <div>Résultat</div>
              <Table striped hover responsive className=''>
                <thead>
                  <tr className='responsive-font-small text-center'>
                    <th colSpan={2} className='border-end'>
                      Arrivée : {searchIslandName}
                      {/* Arrivée : {selectedTrajet && selectedTrajet?.destinationArrivee} */}
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <thead>
                  <tr className='responsive-font-small text-center'>
                    <th className=''>Date</th>
                    <th className='border-end'>Heure</th>
                    <th className=''>Navire</th>
                    <th>N°voyage</th>
                  </tr>
                </thead>
                <tbody className='responsive-font-small text-center'>
                  {selectedTrajet === undefined ? (
                    trajetIslandData?.map((data: any, index: number) => {
                      const isDepartTahiti =
                        data?.destinationDepart === 'Tahiti/PAPEETE' ||
                        data?.destinationDepart === 'Tahiti'
                      const isArriveeTahiti =
                        data?.destinationArrivee === 'Tahiti/PAPEETE' ||
                        data?.destinationArrivee === 'Tahiti'

                      return (
                        <tr
                          key={index}
                          className={'py-3 ' + (isArriveeTahiti ? 'not-allowed' : 'pointer')}
                          onClick={() => {
                            handleSelectTrajetIsland(
                              trajetIslandData,
                              data,
                              setConnaissementBrouillon,
                              connaissementBrouillon
                            )
                          }}
                        >
                          <td
                            className={'p-1 pe-0 p-md-2 ' + (isDepartTahiti && 'text-primary')}
                          >
                            {data?.dateArrivee}
                          </td>
                          <td
                            className={'p-1 pe-0 p-md-2 ' + (isDepartTahiti && 'text-primary')}
                          >
                            {data?.heureArrivee}
                          </td>
                          <td
                            className={'p-1 pe-0 p-md-2 ' + (isDepartTahiti && 'text-primary')}
                          >
                            {data?.nomNavire}
                          </td>

                          <td
                            className={'p-1 pe-0 p-md-2 ' + (isDepartTahiti && 'text-primary')}
                          >
                            {data?.numeroVoyage}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      {/* <td>{selectedTrajet?.dateDepart}</td>
                  <td>{selectedTrajet?.heureDepart}</td>
                  <td>{selectedTrajet?.destinationDepart}</td> */}

                      <td>{selectedTrajet?.dateArrivee}</td>
                      <td>{selectedTrajet?.heureArrivee}</td>
                      <td> {selectedTrajet?.nomNavire}</td>
                      <td>{selectedTrajet?.numeroVoyage}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </React.Fragment>
          )}

          {selectedTrajet !== undefined && (
            <Container fluid className='text-end px-0'>
               {/* {isLoading ? (
                <span className='text-light'>
                  <Spinner size='sm' variant='light' /> loading
                </span>
              ) : (
                <span className=''>Demande</span>
              )} */}
              <Button variant='secondary' onClick={() => handlePostBrouillon('BROUILLON')}>
              {isLoadingBrouillon ? (
                <span className='text-light'>
                  <Spinner size='sm' variant='light' /> Brouillon
                </span>
              ) : (
                <span className=''>Brouillon</span>
              )}
              </Button>{' '}
              <Button variant='success' onClick={() => handlePostDemande('DEMANDE')}>
              {isLoadingDemande ? (
                <span className='text-light'>
                  <Spinner size='sm' variant='light' />                 <span className=''>Demande</span>

                </span>
              ) : (
                <span className=''>Demande</span>
              )}
              </Button>
            </Container>
          )}
          <Alert show={isError?.error} variant='danger' className='mt-3'>
            <i className='ri-error-warning-line text-danger me-2 fs-4'></i>{' '}
            {/* <ErrorMessage errorMessageProps={isError} /> */}
            {isError?.message}
          </Alert>
        </Tab>
      </Tabs>
    </div>
  )
}
