import { errorType } from "../../definitions/errorType"
import ConnaissementServices from "../../services/connaissements/ConnaissementServices"
import IlesService from "../../services/IlesService"
import TrajetsService from "../../services/TrajetsService"
import userStore, { UserState } from "../../stores/userStore"



export const _getIslandByName = async (name: string, setListIsland: any) => {
    try {
      if(name.length > 1){
      const response = await IlesService.getIslandByName(name)
      if (response.data.length > 0) {
        setListIsland(response.data)
      }
    }
    } catch (error) {
      console.log(error)
    }
  }

export  const __getTrajetByIslandId = async (
    idIle: string | null,
    page: number,
    limit: number,
    dateDebut: string,
    dateFin: string,
    nameNavire: string,
    setTrajetData: any,
    setSearchIslandName: any
  ) => {
    try {
      const response = await TrajetsService.getTrajetByIslandId(
        idIle,
        page,
        limit,
        dateDebut,
        dateFin
      )
      const filteredData = response.data?.content.filter(
        (navire: any) => navire.nomNavire === nameNavire
      )
      console.log(response.data)
      if (filteredData?.length > 0) {
        setTrajetData(filteredData)
        setSearchIslandName('')
      } else {
        setTrajetData(response.data?.content)
        setSearchIslandName('')
      }
    } catch (error) {
      console.log(error)
    }
  }

export const _getPlanningOfNavire = async (
    idNavire: string | null,
    page: string,
    limit: string,
    dateDebut: string,
    dateFin: string,
    setSelectedTrajet: any,
    setTrajetData: any,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setIsError: React.Dispatch<React.SetStateAction<errorType>>
  ) => {
    setIsLoading(true)
    setIsError({
      error: false,
      message: "",
    })
    try {
      const response = await TrajetsService.getTrajetByNavireId(
        idNavire,
        page,
        limit,
        dateDebut,
        dateFin
      )

      if(response.data.content?.length === 0 ){
        setIsLoading(false)
        setIsError({
          error: true,
          message: "Aucun trajet trouvé",
        })
        setSelectedTrajet(undefined)
        setTrajetData(undefined)

      }
      else if (response.data.content.length > 0) {
        setSelectedTrajet(undefined)
        setTrajetData(response.data.content)
        setIsLoading(false)

      }
    } catch (error: any) {
      console.log(error)
    setIsLoading(false)
    setIsError({
      error: true,
      message: error?.response?.data?.message,
    })

    }
  }

export const _getPlanningMooz = async (
  idNavire: number,
  page: string,
  limit: string,
  dateDebut: string,
  dateFin: string,
  setSelectedTrajet: any,
  setTrajetData: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsError: React.Dispatch<React.SetStateAction<errorType>>
) => {
  setIsLoading(true)
  setIsError({
    error: false,
    message: "",
  })
  try {

    const response = await TrajetsService.getPlanningMoorea(idNavire, dateDebut, dateFin, 29)

    if (response.data?.length === 0) {
      setIsError({
        error: true,
        message: "Aucun trajet trouvé",
      })
      setSelectedTrajet(undefined)
      setTrajetData(undefined)
      setIsLoading(false)
    } else if (response.data.length > 0) {
      setSelectedTrajet(undefined)
      setTrajetData(response.data)
      setIsLoading(false)
    }
  } catch (error: any) {
    console.log(error)
    setIsLoading(false)
    setIsError({
      error: true,
      message: error?.response?.data?.message,
    })
  }
}

export  const _getTrajetByIslandId = async (
  idIle: string | null,
  page: number,
  limit: number,
  dateDebut: string,
  dateFin: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsError: React.Dispatch<React.SetStateAction<errorType>>,
  setTrajetIslandData: any
) => {
  setIsLoading(true)
  setIsError({
    error: false,
    message: "",
  })
  try {
    const response = await TrajetsService.getTrajetByIslandId(
      idIle,
      page,
      limit,
      dateDebut,
      dateFin
    )
    setIsLoading(false)
    console.log(response)
    if (response.data.content?.length === 0) {
      setIsError({
        error: true,
        message: "Aucun trajet trouvé",
      })
    }else{
      setTrajetIslandData(response.data?.content)
    }

  } catch (error: any) {
    console.log(error)
    setIsLoading(false)
    setIsError({
      error: true,
      message: error?.response?.data?.message,
    })
  }
}


export const _refreshToken = async (token: string | null, id: number) => {
  try {
    const response = await ConnaissementServices.getrefreshToken(token, id)
    // console.log(response?.data.access_token)
    userStore
      .getState()
      .authLogin(
        null,
        null,
        null,
        null,
        token,
        null,
        null,
        null,
        null,
        response?.data?.access_token
      )
  } catch (error) {
    console.log(error)
  }
}


export const _connaissementDataTable = async (setIsLoading: any, setConnaissementData: any, currentPage: number, limit: number) => {
  const dataStore =  userStore((state: UserState) => state)
  setIsLoading(true)
  try {
    const response = await ConnaissementServices.getConnaissement(dataStore?.access_token, currentPage, limit)
    console.log(response.data)
    setConnaissementData(response?.data?.content)
    setIsLoading(false)
  } catch (error: any) {
    console.log(error)
    setIsLoading(false)
    if (error?.response?.data?.error === 'invalid_token') {
      const idCompany = dataStore?.company && dataStore?.company[0]?.id_company
      _refreshToken(dataStore?.token, idCompany)
    }
  }
}