import React from 'react'
import { Navbar, Container, Row, Col} from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function BottomNavBar() {
  return (
    <Navbar
    fixed='bottom'
    expand='lg'
    className='bg-body-tertiary responsive-font-small d-block d-md-none'
  >
    <Container >
      <div>
        <Link className='text-decoration-none' to='/factures'>
          <Row className=' menu-link text-center'>
            <Col xs={12} lg={2} className=''>
              {' '}
              <i className='ri-file-text-line fs-5'></i>
            </Col>{' '}
            <Col className='m-auto d-sm-block'>Factures</Col>
          </Row>
        </Link>
      </div>
      <div>
        {' '}
        <Link className='text-decoration-none' to='/connaissements'>
          <Row className=' menu-link text-center'>
            <Col xs={12} lg={2} className='m-auto'>
              {' '}
              <i className='ri-sailboat-line fs-5'></i>
            </Col>{' '}
            <Col className='m-auto d-sm-block'>
              Connaissements
            </Col>
          </Row>
        </Link>
      </div>
    </Container>
  </Navbar>
  )
}
