import axios from "axios";

const API_URL = process.env.REACT_APP_END_POINT
const API_URL_TOTARA = process.env.REACT_APP_REMORA_END_POINT

class ConnaissementService {
  postBrouillonConnaissement(token: string, data: any) {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/brouillons`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: data,
    }
    return axios.request(config)
  }
  postDemandeConnaissement(token: string, data: any) {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/demandes`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: data,
    }
    return axios.request(config)
  }

  getConnaissement(token: string, page: number, limit: any) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/demandes?sort=id,desc&limit=${limit}&page=${page}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }

  getFilteredConnaissement(token: string, page: number, filteringData: string) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/demandes?sort=id,desc&page=${page}&${filteringData}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }

  getrefreshToken(token: string | null, id: number) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL_TOTARA}/api/v1/companies/${id}/token`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }
  updateConnaissement(token: string, data: any, id: number) {
    const config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/${id}/changeretat`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: data,
    }
    return axios.request(config)
  }
  updateNbPalette(token: string, data: any, id: number) {
    const config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/brouillons/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: data,
    }
    return axios.request(config)
  }

  deleteBrouillon(token: string, id: number) {
    const config = {
      method: 'delete',
      maxBodyLength: Infinity,//demandes/43601?isDemandeParArmateur=false
      url: `${API_URL}/api/v1/connaissements/demandes/${id}?isDemandeParArmateur=false`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }
  

  getPdf(token: string, id: number) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/${id}/etiquette`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }
}

 
// eslint-disable-next-line import/no-anonymous-default-export
export default new ConnaissementService();
