import UserService from "../../services/user/UserService"
import CompaniesService from "../../services/companies/CompaniesService"
import OrdersService from "../../services/orders/OrdersService"



/////////////////////////
///users
////////////////////////


export const _getUsersData = async (token: string, setUserData: any, setIsLoading: any) => {
  setIsLoading(true)
  try {
    let page = 1
    let allCompanies: any = []
    let hasMorePages = true

    while (hasMorePages) {
    const response = await UserService.getUsers(token, page)
    allCompanies = [...allCompanies, ...response.data.data]

    if (response.data.meta.current_page < response.data.meta.last_page) {
      page++
    } else {
      hasMorePages = false
    }
  }

    // setUserData(response?.data.data)
    setUserData(allCompanies)
    setIsLoading(false)
  } catch (error: any) {
    console.log(error)
  }
}




////////////////////////
///companies
///////////////////////



export  const _getCompaniesData = async (token: any, setCompaniesData: any, setIsLoading: any) => {
  setIsLoading(true)
  
  try {
    let page = 1
    let allCompanies: any = []
    let hasMorePages = true

    while (hasMorePages) {
      const response = await CompaniesService.getCompanies(token, page)
      allCompanies = [...allCompanies, ...response.data.data]

      if (response.data.meta.current_page < response.data.meta.last_page) {
        page++
      } else {
        hasMorePages = false
      }
    }
     setIsLoading(false)
    setCompaniesData(allCompanies)
  } catch (error) {
     setIsLoading(false)
    console.log(error)
  }
}


////////////////////////
///Orders
///////////////////////

//formatte orders
export const _transformDataToNested = (data: any) => {
  const result: any = []

  data?.forEach((item: any) => {

      result.push({
        expediteur: {
          denomination: item.expediteur_denomination,
          telephone: item.expediteur_telephone,
          mail: item.expediteur_mail,
          numeroTahiti: item.expediteur_numeroTahiti,
        },

        id: item.id,
        id_connaissement: item.id_connaissement,
        id_company: item.id_company,
        numeroVoyage: item.numeroVoyage,
        paiement: item.paiement,
        ileDepart: item.ileDepart,
        ileArrivee: item.ileArrivee,
        lieuArrivee: item.lieuArrivee,
        date_etl: item.date_etl,
        date_creation: item.date_creation,
        dateModif: item.dateModif,
        statusRevatua: item.statut_revatua,
        referenceHorsRevatua: item.referenceHorsRevatua,
        destinataire: {
          denomination: item.destinataire_denomination,
          telephone: item.destinataire_telephone,
          mail: item.destinataire_mail,
          numeroTahiti: item.destinataire_numeroTahiti,
        },
        items: item.items,
      })
  })

  return result
}


//get orders
export const _getOrdersData = async (token: string, setDataOrder: any, setIsLoading: any, setErrorOrderMessage: any) => {
  setIsLoading(true)
  try{

    let page = 1
    let allOrders: any = []
    let hasMorePages = true

    while (hasMorePages) {
      const response = await OrdersService.getOrders(token, page)
      allOrders = [...allOrders, ..._transformDataToNested(response.data.data)]
      // allOrders = [...allOrders, ...response.data.data]
      // if (response.data.meta.current_page < 10) {
        if (response.data.meta.current_page < response.data.meta.last_page) {
        page++
      } else {
        hasMorePages = false
      }
    }
    
    
    setDataOrder(allOrders)
    setIsLoading(false)
      setErrorOrderMessage({
        message: "",
        isError: false,
      })

  }catch(error: any){
      setIsLoading(false)
      setErrorOrderMessage({
        message: error?.message,
        isError: true,
      })
      console.log(error)
  }
}

//add a producting existing order
export const _handleAddproduct = async (token: any, orderData: any ) => {
  try {
    await OrdersService.addItemsOrder(token, orderData)
  } catch (error) {
    console.log(error)
  }
}

//edit a product 
export const _handleUpdateProduct = async (token: string, orderData: any, id: number, setIsError: any, handleCloseUpdateProductModal: any) => {
  setIsError({
    error: false,
    message: "",
  })
  try {
    await OrdersService.updateItemsOrder(token, orderData, id)
    setIsError({
      error: false,
      message: "",
    })
    handleCloseUpdateProductModal()
  } catch (error: any) {
    console.log(error)
    setIsError({
      error: true,
      message: error?.response?.data?.message ? error?.response?.data?.message :  error?.message === "Network Error" ? "Oups, une erreur est survenue. Vérifiez votre connexion et réessayer." : error?.message
      // message: error?.response?.data?.message,
    })
  }
}

//filter orders
export const _filteredOrder = async(token: any, filteringData: any) => {

  try{
    await OrdersService.filteredOrder(token, filteringData, 1)
  
  }catch(error){
    console.log(error)
  }
}

//filter products
export const _filteredItem = async(token: any, filteringData: any, id: number) => {

  try{
    await OrdersService.filteredItem(token, filteringData, id)
    
  }catch(error){
    console.log(error)
  }
}
//switch status order : A_PLANIFIER/A_DEPLANIFIER
export const _switchStatus = async (token: string, status: string, id: number | null, setErrorOrderMessage: any, dataOrder: any, setDataOrder: any, setShowErrorOrder: any) => {

  const bodyData = {
    statut_revatua: status,
  }
  setErrorOrderMessage({
    error: false,
    message: '',
  })

  try {
    const orderPromises = await OrdersService.updateOrder(token, bodyData, id)

    if (orderPromises.status === 200) {
      const order = dataOrder?.map((order: any) => {
        if (order.id === id) {
          return {
            ...order,
            statusRevatua: status,
          }
        }
        return order
      })
      setDataOrder(order)
    }
  } catch (error: any) {
    console.log(error)
    const messageError = JSON.parse(error?.request.responseText)?.data?.statut_revatua
      ? JSON.parse(error?.request.responseText)?.data?.statut_revatua[0]
      : error?.response?.data?.data?.message
    setShowErrorOrder(true)
    setErrorOrderMessage({
      error: true,
      message: messageError,
    })
  }
}
